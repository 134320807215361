import { ApolloClient, createHttpLink, InMemoryCache, ApolloLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { computeLocalQueryPolicies } from './store/store';
import config from './config.json';
import { onError } from '@apollo/client/link/error';

const httpLink = createHttpLink({
  uri: config.ODOO_HOST + config.GRAPHQL_ENDPOINT,
  ...(() => config.AUTH_METHOD === 'cookie' ? { credentials: 'include' } : {})(),
});

const authLink = config.AUTH_METHOD === 'token' ? 
  setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists
    const token = localStorage.getItem('session_id');
    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        'X-Openerp-Session-Id': token ? `${token}` : '',
      }
    };
  }) : 
  null;

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) => {
      // console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`);
    });
  }
  if (networkError) {
    // console.log(`[Network error]: ${networkError}`);
  }
});

const client = new ApolloClient({
  link: ApolloLink.from([
    ...(() => authLink ? [authLink] : [])(),
    errorLink,
    httpLink,
  ]),
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: computeLocalQueryPolicies(),
      }
    }
  }),
});

export default client;