import "./App.css";
import { useQuery, useMutation, gql } from "@apollo/client";
import {
  Button,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton,
  Dialog,
  Paper,
  Typography,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { Cached, CheckBox, CheckBoxOutlineBlank, Error, Refresh, RefreshRounded } from "@mui/icons-material";
import { useEffect, useState } from "react";
import DatesForm from "./components/DatesForm";
import EditableField from "./components/EditableField";
import Login from "./Login";

const GET_DATA = gql`
  query allClients {
    searchErpClient {
      id
      name
      dateDebutAnalyse
      dateFinAnalyse
      dateAnalyseEncours
      dateAnalyseEncoursCustomer
    }
    searchErpClientGroup {
      id
      name
      dateDebutAnalyse
      dateFinAnalyse
      dateAnalyseEncours
    }
  }
`;

const EDIT_CLIENT = gql`
  mutation editClient(
    $id: Int!
    $dateDebutAnalyse: String
    $dateFinAnalyse: String
    $dateAnalyseEncours: String
    $dateAnalyseEncoursCustomer: String
  ) {
    updateErpClient(
      id: $id
      dateDebutAnalyse: $dateDebutAnalyse
      dateFinAnalyse: $dateFinAnalyse
      dateAnalyseEncours: $dateAnalyseEncours
      dateAnalyseEncoursCustomer: $dateAnalyseEncoursCustomer
    ) {
      updated {
        id
        name
        dateDebutAnalyse
        dateFinAnalyse
        dateAnalyseEncours
        dateAnalyseEncoursCustomer
      }
    }
  }
`;

const EDIT_GROUP = gql`
  mutation editGroup(
    $id: Int!
    $dateDebutAnalyse: String!
    $dateFinAnalyse: String!
    $dateAnalyseEncours: String!
  ) {
    updateErpClientGroup(
      id: $id
      dateDebutAnalyse: $dateDebutAnalyse
      dateFinAnalyse: $dateFinAnalyse
      dateAnalyseEncours: $dateAnalyseEncours
    ) {
      updated {
        id
        name
        dateDebutAnalyse
        dateFinAnalyse
        dateAnalyseEncours
      }
    }
  }
`;

const REFRESH_PORTEFEUILLE = gql`
  mutation refreshPortefeuille($id: Int!, $model: String) {
    refreshPortefeuille(id: $id, model: $model) {
      ok
    }
  }
`;

const REFRESH_ENCOURS = gql`
  mutation refreshEncours($id: Int!, $model: String) {
    refreshEncours(id: $id, model: $model) {
      ok
    }
  }
`;

const REFRESH_ENCOURS_CUSTOMER = gql`
  mutation refreshEncoursCustomer($id: Int!, $model: String) {
    refreshEncoursCustomer(id: $id, model: $model) {
      ok
    }
  }
`;

const App = () => {
  const { loading, error, data } = useQuery(GET_DATA);
  const [
    editClient,
    { loading: editingClient, error: failedEditionClient, data: editedClient },
  ] = useMutation(EDIT_CLIENT);
  const [
    editGroup,
    { loading: editingGroup, error: failedEditionGroup, data: editedGroup },
  ] = useMutation(EDIT_GROUP);
  const [refreshPortefeuille] = useMutation(REFRESH_PORTEFEUILLE);
  const [refreshEncours] = useMutation(REFRESH_ENCOURS);
  const [refreshEncoursCustomer] = useMutation(REFRESH_ENCOURS_CUSTOMER);
  const [clientGlobalSetOpen, setClientGlobalSetOpen] = useState(false);
  const [groupGlobalSetOpen, setGroupGlobalSetOpen] = useState(false);
  const [loadingState, setLoadingState] = useState({
    isLoading: null,
  });
  const [loadingOpen, setLoadingOpen] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(true);

  const [refreshingClient, setRefreshingClient] = useState(null);
  const [refreshingGroup, setRefreshingGroup] = useState(null);
  const [dates, setDates] = useState({
    dateDebutAnalyse: "",
    dateFinAnalyse: "",
    dateAnalyseEncours: "",
    dateAnalyseEncoursCustomer: "",
  });

  useEffect(() => {
    if (error) {
      setIsLoggedIn(false);
    } else if (!loading && data) {
      setIsLoggedIn(true);
      if (loadingState.clients === undefined) {
        setLoadingState({
          isLoading: null,
          clients: data.searchErpClient.reduce((acc, client, index) => {
            return {
              ...acc,
              [client.id]: {
                name: client.name,
                loading: false,
                done: false,
                error: false,
              },
            };
          }, {}),
          groups: data.searchErpClientGroup.reduce((acc, group, index) => {
            return {
              ...acc,
              [group.id]: {
                name: group.name,
                loading: false,
                done: false,
                error: false,
              },
            };
          }, {})
        });
      }
    }
  }, [loading, error, data, loadingState]);

  const refreshEntity = async ({ model, id }) => {
    await refreshPortefeuille({
      variables: {
        model,
        id,
      },
    });
    await refreshEncours({
      variables: {
        model,
        id,
      },
    });
    await refreshEncoursCustomer({
      variables: {
        model,
        id,
      },
    });
  };

  const handleDatesChange = async (
    type,
    { dateDebutAnalyse, dateFinAnalyse, dateAnalyseEncours, dateAnalyseEncoursCustomer }
  ) => {
    if (!loading && !error && data) {
      const loadingStateCopy = { ...loadingState };
      setDates({
        dateDebutAnalyse,
        dateFinAnalyse,
        dateAnalyseEncours,
        dateAnalyseEncoursCustomer,
      });
      if (type === "clients") {
        loadingStateCopy.isLoading = 'clients';
        for (let i = 0; i < data.searchErpClient.length; ++i) {
          const client = data.searchErpClient[i];
          loadingStateCopy.clients[client.id].loading = true;
          loadingStateCopy.clients[client.id].done = false;
          loadingStateCopy.clients[client.id].error = false;
          setLoadingState(loadingStateCopy);
          setLoadingOpen(true);

          try {
            // Divide calls to update every calculation one by one
            if ((dateDebutAnalyse !== "" && dateDebutAnalyse !== client.dateDebutAnalyse) || (dateFinAnalyse !== "" && dateFinAnalyse !== client.dateFinAnalyse)) {
              await editClient({
                variables: {
                  id: client.id,
                  dateDebutAnalyse,
                  dateFinAnalyse,
                },
              });
            }
            if (dateAnalyseEncours !== "" && dateAnalyseEncours !== client.dateAnalyseEncours) {
              await editClient({
                variables: {
                  id: client.id,
                  dateAnalyseEncours,
                },
              });
            }
            if (dateAnalyseEncoursCustomer !== "" && dateAnalyseEncoursCustomer !== client.dateAnalyseEncoursCustomer) {
              await editClient({
                variables: {
                  id: client.id,
                  dateAnalyseEncoursCustomer,
                },
              });
            }
            await refreshEntity({
              id: client.id,
              model: 'erp.client',
            });
            loadingStateCopy.clients[client.id].done = true;
          } catch (e) {
            loadingStateCopy.clients[client.id].error = true;
            console.error(e);
          } finally {
            loadingStateCopy.clients[client.id].loading = false;
            setLoadingState(loadingStateCopy);
          }
        }
        setClientGlobalSetOpen(false);
      } else if (type === "groups") {
        loadingStateCopy.isLoading = 'groups';
        for (let i = 0; i < data.searchErpClientGroup.length; ++i) {
          const group = data.searchErpClientGroup[i];
          loadingStateCopy.groups[group.id].loading = true;
          loadingStateCopy.groups[group.id].done = false;
          loadingStateCopy.groups[group.id].error = false;
          setLoadingState(loadingStateCopy);
          setLoadingOpen(true);

          try {
            await editGroup({
              variables: {
                id: group.id,
                dateDebutAnalyse:
                  dateDebutAnalyse !== ""
                    ? dateDebutAnalyse
                    : group.dateDebutAnalyse,
                dateFinAnalyse:
                  dateFinAnalyse !== "" ? dateFinAnalyse : group.dateFinAnalyse,
                dateAnalyseEncours:
                  dateAnalyseEncours !== ""
                    ? dateAnalyseEncours
                    : group.dateAnalyseEncours,
              },
            });
            await refreshEntity({
              id: group.id,
              model: 'erp.client.group',
            });
            loadingStateCopy.groups[group.id].done = true;
          } catch (e) {
            loadingStateCopy.groups[group.id].error = true;
            console.error(e);
          } finally {
            loadingStateCopy.groups[group.id].loading = false;
            setLoadingState(loadingStateCopy);
          }
        }
        setGroupGlobalSetOpen(false);
      }
    }
  };

  const retrySetDates = async (type, client) => {
    const loadingStateCopy = { ...loadingState };
    const {
      dateDebutAnalyse,
      dateFinAnalyse,
      dateAnalyseEncours,
      dateAnalyseEncoursCustomer,
    } = dates;
    console.log('retrySetDates', {type, client});
    if (type === "clients") {
      loadingStateCopy.clients[client.id].loading = true;
      loadingStateCopy.clients[client.id].done = false;
      loadingStateCopy.clients[client.id].error = false;
      setLoadingState(loadingStateCopy);
      setLoadingOpen(true);

      try {
        // Divide calls to update every calculation one by one
        if ((dateDebutAnalyse !== "" && dateDebutAnalyse !== client.dateDebutAnalyse) || (dateFinAnalyse !== "" && dateFinAnalyse !== client.dateFinAnalyse)) {
          await editClient({
            variables: {
              id: client.id,
              dateDebutAnalyse,
              dateFinAnalyse,
            },
          });
        }
        if (dateAnalyseEncours !== "" && dateAnalyseEncours !== client.dateAnalyseEncours) {
          await editClient({
            variables: {
              id: client.id,
              dateAnalyseEncours,
            },
          });
        }
        if (dateAnalyseEncoursCustomer !== "" && dateAnalyseEncoursCustomer !== client.dateAnalyseEncoursCustomer) {
          await editClient({
            variables: {
              id: client.id,
              dateAnalyseEncoursCustomer,
            },
          });
        }
        await refreshEntity({
          id: client.id,
          model: 'erp.client',
        });
        loadingStateCopy.clients[client.id].done = true;
      } catch (e) {
        loadingStateCopy.clients[client.id].error = true;
        console.error(e);
      } finally {
        loadingStateCopy.clients[client.id].loading = false;
        setLoadingState(loadingStateCopy);
      }
    } else if (type === "groups") {
      loadingStateCopy.groups[client.id].loading = true;
      loadingStateCopy.groups[client.id].done = false;
      loadingStateCopy.groups[client.id].error = false;
      setLoadingState(loadingStateCopy);
      setLoadingOpen(true);

      try {
        await editGroup({
          variables: {
            id: client.id,
            dateDebutAnalyse:
              dateDebutAnalyse !== ""
                ? dateDebutAnalyse
                : client.dateDebutAnalyse,
            dateFinAnalyse:
              dateFinAnalyse !== "" ? dateFinAnalyse : client.dateFinAnalyse,
            dateAnalyseEncours:
              dateAnalyseEncours !== ""
                ? dateAnalyseEncours
                : client.dateAnalyseEncours,
          },
        });
        await refreshEntity({
          id: client.id,
          model: 'erp.client.group',
        });
        loadingStateCopy.groups[client.id].done = true;
      } catch (e) {
        loadingStateCopy.groups[client.id].error = true;
        console.error(e);
      } finally {
        loadingStateCopy.groups[client.id].loading = false;
        setLoadingState(loadingStateCopy);
      }
    }
  };

  if (loading) {
    return <CircularProgress />;
  } else if (!isLoggedIn) {
    return (
      <Login />
    );
  } else if (data) {
    return (
      <>
        <Paper sx={{ margin: 5, padding: 5 }} elevation={10}>
          <Typography variant='h3' sx={{ textAlign: 'center', marginBottom: 2 }}>Clients</Typography>
          <Button sx={{ float: 'right', margin: '0 20px 10px 0'}} onClick={() => setClientGlobalSetOpen(true)} variant="contained">
            Assigner des dates à tous les clients
          </Button>
          <DatesForm
            open={clientGlobalSetOpen}
            onCancel={() => setClientGlobalSetOpen(false)}
            onSave={dates => handleDatesChange("clients", dates)}
            type="clients"
          />
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>id</TableCell>
                <TableCell>Nom</TableCell>
                <TableCell>Date de début portefeuille</TableCell>
                <TableCell>Date de fin portefeuille</TableCell>
                <TableCell>Date d'analyse encours</TableCell>
                <TableCell>Date d'analyse encours espace client</TableCell>
                <TableCell>Actualiser les calculs</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.searchErpClient.map(client => (
                <TableRow key={client.id}>
                  <TableCell>{client.id}</TableCell>
                  <TableCell>{client.name}</TableCell>
                  <TableCell>
                    <EditableField
                      initialValue={client.dateDebutAnalyse}
                      mutation={gql`
                      mutation edit($newValue: String!) {
                        updateErpClient(id: ${client.id}, dateDebutAnalyse: $newValue) {
                          updated {
                            id
                            dateDebutAnalyse
                          }
                        }
                      }
                    `}
                    />
                  </TableCell>
                  <TableCell>
                    <EditableField
                      initialValue={client.dateFinAnalyse}
                      mutation={gql`
                      mutation edit($newValue: String!) {
                        updateErpClient(id: ${client.id}, dateFinAnalyse: $newValue) {
                          updated {
                            id
                            dateFinAnalyse
                          }
                        }
                      }
                    `}
                    />
                  </TableCell>
                  <TableCell>
                    <EditableField
                      initialValue={client.dateAnalyseEncours}
                      mutation={gql`
                      mutation edit($newValue: String!) {
                        updateErpClient(id: ${client.id}, dateAnalyseEncours: $newValue) {
                          updated {
                            id
                            dateAnalyseEncours
                          }
                        }
                      }
                    `}
                    />
                  </TableCell>
                  <TableCell>
                    <EditableField
                      initialValue={client.dateAnalyseEncoursCustomer}
                      mutation={gql`
                      mutation edit($newValue: String!) {
                        updateErpClient(id: ${client.id}, dateAnalyseEncoursCustomer: $newValue) {
                          updated {
                            id
                            dateAnalyseEncoursCustomer
                          }
                        }
                      }
                    `}
                    />
                  </TableCell>
                  <TableCell>
                    <IconButton
                      onClick={async () => {
                        setRefreshingClient(client.id);
                        await refreshEntity({
                          id: client.id,
                          model: "erp.client",
                        });
                        setRefreshingClient(null);
                      }}
                    >
                    {refreshingClient === client.id ? <CircularProgress /> :
                      <Refresh />}
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>
        <Paper sx={{ margin: 5, padding: 5 }} elevation={10}>
          <Typography variant='h3' sx={{ textAlign: 'center', marginBottom: 2 }}>Groupes</Typography>
          <Button sx={{ float: 'right', margin: '0 20px 10px 0'}} onClick={() => setGroupGlobalSetOpen(true)} variant="contained">
            Assigner des dates à tous les groupes
          </Button>
          <DatesForm
            open={groupGlobalSetOpen}
            onCancel={() => setGroupGlobalSetOpen(false)}
            onSave={dates => handleDatesChange("groups", dates)}
            type="groupes"
          />
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>id</TableCell>
                <TableCell>Nom</TableCell>
                <TableCell>Date de début portefeuille</TableCell>
                <TableCell>Date de fin portefeuille</TableCell>
                <TableCell>Date d'analyse encours</TableCell>
                <TableCell>Actualiser les calculs</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.searchErpClientGroup.map(group => (
                <TableRow key={group.id}>
                  <TableCell>{group.id}</TableCell>
                  <TableCell>{group.name}</TableCell>
                  <TableCell>
                    <EditableField
                      initialValue={group.dateDebutAnalyse}
                      mutation={gql`
                      mutation edit($newValue: String!) {
                        updateErpClientGroup(id: ${group.id}, dateDebutAnalyse: $newValue) {
                          updated {
                            id
                            dateDebutAnalyse
                          }
                        }
                      }
                    `}
                    />
                  </TableCell>
                  <TableCell>
                    <EditableField
                      initialValue={group.dateFinAnalyse}
                      mutation={gql`
                      mutation edit($newValue: String!) {
                        updateErpClientGroup(id: ${group.id}, dateFinAnalyse: $newValue) {
                          updated {
                            id
                            dateFinAnalyse
                          }
                        }
                      }
                    `}
                    />
                  </TableCell>
                  <TableCell>
                    <EditableField
                      initialValue={group.dateAnalyseEncours}
                      mutation={gql`
                      mutation edit($newValue: String!) {
                        updateErpClientGroup(id: ${group.id}, dateAnalyseEncours: $newValue) {
                          updated {
                            id
                            dateAnalyseEncours
                          }
                        }
                      }
                    `}
                    />
                  </TableCell>
                  <TableCell>
                    <IconButton
                      onClick={async () => {
                        setRefreshingGroup(group.id);
                        await refreshEntity({
                          id: group.id,
                          model: "erp.client.group",
                        });
                        setRefreshingGroup(null);
                      }}
                    >
                      {refreshingGroup === group.id ? <CircularProgress /> : <Refresh />}
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          {(editingClient || editingGroup) && <CircularProgress />}
          <Dialog open={loadingOpen} sx={{ padding: 4 }}>
            <DialogTitle>Mise à jour</DialogTitle>
            <DialogContent>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Nom</TableCell>
                    <TableCell>État</TableCell>
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {loadingState.isLoading && 
                    Object.entries(loadingState[loadingState.isLoading]).map(([clientId, client]) => (
                      <TableRow key={client.name}>
                        <TableCell>{client.name}</TableCell>
                        <TableCell>
                          {client.error ? <Error style={{ color: 'red' }} /> : 
                          client.done ? <CheckBox /> : 
                          client.loading ? <Cached /> :     
                            <CheckBoxOutlineBlank />}
                        </TableCell>
                        <TableCell>
                          {(client.error) && 
                            <Button onClick={() => retrySetDates(loadingState.isLoading, {id: clientId, ...client})}>
                              <RefreshRounded />
                            </Button>
                          }
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setLoadingOpen(false)}>Fermer</Button>
            </DialogActions>
          </Dialog>
        </Paper>
      </>
    );
  }
};

export default App;
