import React, { useState } from 'react';
import { Dialog, TextField, Button, Typography } from '@mui/material';

const DatesForm = ({ 
  open, 
  init = {
    dateDebutAnalyse: '',
    dateFinAnalyse: '',
    dateAnalyseEncours: '',
  }, 
  onSave, 
  onCancel,
  type
}) => {
  const [ dateDebutAnalyse, setDateDebutAnalyse ] = useState(init.dateDebutAnalyse);
  const [ dateFinAnalyse, setDateFinAnalyse ] = useState(init.dateFinAnalyse);
  const [ dateAnalyseEncours, setDateAnalyseEncours ] = useState(init.dateAnalyseEncours);
  const [ dateAnalyseEncoursCustomer, setDateAnalyseEncoursCustomer ] = useState(init.dateAnalyseEncoursCustomer);

  return (
    <Dialog
      open={open}
      onClose={onCancel}
    >
      <div style={{display: 'flex', flexDirection: 'column', margin: 20 }}>
        <Typography sx={{ marginBottom: 1 }}>Ces dates seront appliquées à tous les {type}</Typography>
        <TextField
            id="dateDebutAnalyse"
            label="Date de début portefeuille"
            value={dateDebutAnalyse}
            onChange={e => setDateDebutAnalyse(e.target.value)}
            sx={{ margin: 1 }}
          />
          <TextField
            id="dateFinAnalyse"
            label="Date de fin portefeuille"
            value={dateFinAnalyse}
            onChange={e => setDateFinAnalyse(e.target.value)}
            sx={{ margin: 1 }}
          />
          <TextField
            id="dateAnalyseEncours"
            label="Date encours"
            value={dateAnalyseEncours}
            onChange={e => setDateAnalyseEncours(e.target.value)}
            sx={{ margin: 1 }}
          />
          {type === 'clients' && (
            <TextField
              id="dateAnalyseEncoursCustomer"
              label="Encours espace client"
              value={dateAnalyseEncoursCustomer}
              onChange={e => setDateAnalyseEncoursCustomer(e.target.value)}
              sx={{ margin: 1 }}
            />
          )}
          <div style={{ display: 'flex', flexDirection: 'row', marginTop: 10 }}>
            <Button
              onClick={onCancel}
              variant='outlined'
              color="secondary"
              sx={{ flex: 1 }}
            >
              Cancel
            </Button>
            <Button
              onClick={() => onSave({ dateDebutAnalyse, dateFinAnalyse, dateAnalyseEncours, dateAnalyseEncoursCustomer })}
              variant='contained'
              color="secondary"
              sx={{ flex: 1 }}
            >
              Save
            </Button>
          </div>
        </div>
    </Dialog>
  )
};

export default DatesForm;