import React, { useState } from 'react';
import { TextField, Button, Paper, Typography } from '@mui/material';
import config from './config.json';

const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const login = async () => {
    const response = await fetch(
      `${config.ODOO_HOST}/web/session/authenticate`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          params: {
            db: "a7plus",
            login: username,
            password,
          },
        }),
      }
    );

    if (response.status === 200) {
      const loginData = await response.json();

      if (
        loginData.error &&
        loginData.error.data.name === "odoo.exceptions.AccessDenied"
      ) {
        alert("Mauvais identifiants");
      } else {
        localStorage.setItem("session_id", loginData.result.session_id);
        window.location.reload();
      }
    }
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      <Paper sx={{ margin: 10, display: 'flex', flexDirection: 'column', padding: 10, maxWidth: 500 }}>
        <Typography variant='h5' sx={{ textAlign: 'center', marginBottom: 5 }}>Connexion</Typography>
        <TextField
          label='username'
          onChange={event => setUsername(event.target.value)}
          value={username}
          sx={{ marginBottom: 2 }}
        />
        <TextField
          label='password'
          type='password'
          onChange={event => setPassword(event.target.value)}
          onKeyDown={event => event.key === 'Enter' && login()}
          value={password}
          sx={{ marginBottom: 2 }}
        />
        <Button onClick={login} variant="contained">Se connecter</Button>
      </Paper>
    </div>
  );
};

export default Login;