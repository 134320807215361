
import { makeVar, ReactiveVar } from '@apollo/client';

const variables = {};

const dataStores = {
  memory: {
    getInitialValue: (key, defaultValue) => defaultValue,
    setValue: () => {},
  },
  sessionStorage: {
    getInitialValue: (key, defaultValue) => {
      const storageItem = window.sessionStorage.getItem(key);

      if (storageItem === null) {
        window.sessionStorage.setItem(key, JSON.stringify(defaultValue));
        return defaultValue;
      }

      return JSON.parse(storageItem);
    },
    setValue: (key, value) => {
      window.sessionStorage.setItem(key, JSON.stringify(value));
    },
  },
  localStorage: {
    getInitialValue: (key, defaultValue) => {
      const storageItem = window.localStorage.getItem(key);
      
      if (storageItem === null) {
        window.localStorage.setItem(key, JSON.stringify(defaultValue));
        return defaultValue;
      }

      return JSON.parse(storageItem);
    },
    setValue: (key, value) => {
      window.localStorage.setItem(key, JSON.stringify(value));
    },
  },
};

export const storeKey = (key, defaultValue, dataStore = 'memory') => {

  const initialValue = dataStores[dataStore].getInitialValue(key, defaultValue);

  const variable = makeVar(initialValue);

  variables[key] = variable;

  return (value) => {
    dataStores[dataStore].setValue(key, value);
    variable(value);
  };
};

export const computeLocalQueryPolicies = () => {

  const queryPolicies = {};
  
  return Object.entries(variables).reduce((queryPolicies, [key, variable]) => {
    queryPolicies[key] = {
      read: () => variable(),
    };

    return queryPolicies;
  }, queryPolicies);
};
