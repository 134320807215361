import React, { useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import { TextField, IconButton, CircularProgress } from '@mui/material';
import { Edit, Save } from '@mui/icons-material';

const EditableField = ({
  initialValue = '',
  mutation,
}) => {
  const [ newValue, setNewValue ] = useState(initialValue);
  const [ editing, setEditing ] = useState(false);

  const [ mutate, { loading, error, data }] = useMutation(mutation);

  useEffect(() => {
    setNewValue(initialValue);
  }, [initialValue]);

  const handleSave = async () => {
    await mutate({
      variables: {
        newValue,
      },
    });
    setEditing(false);
  };

  return editing ? 
    <>
      <TextField
        value={newValue}
        onChange={e => setNewValue(e.target.value)}
      />
      <IconButton
        onClick={handleSave}
      >
        {loading ? <CircularProgress /> : <Save />}
      </IconButton>
    </> : 
    <>
      {newValue}
      <IconButton
        onClick={() => setEditing(true)}
      >
        <Edit />
      </IconButton>
    </>;
};

export default EditableField;